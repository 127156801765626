(function () {
  'use strict';

  class PollReportCtrl {
    constructor(Utils, Groups, HtmlDialog, $window) {
      let vm = this;
      vm.ctrlName = 'PollReportCtrl';
      vm.Utils = Utils;
      vm.HtmlDialog = HtmlDialog;
      vm.Groups = Groups;
      vm.barChartOptions = {
        chart: {
          type: 'discreteBarChart',
          height: 450,
          x: function (d) {
            return d.label;
          },
          y: function (d) {
            return d.value;
          },
          showValues: true,
          valueFormat: function (d) {
            return $window.d3.format(',d')(d);
          },
          duration: 500,
          xAxis: {
            axisLabel: 'Answer'
          },
          yAxis: {
            axisLabel: 'Count',
            axisLabelDistance: -10
          }
        }
      };
      vm.init();
    }
    showHtml(e, html) {
      let vm = this;
      vm.HtmlDialog.show(e, html);
    }
    init() {
      let vm = this;
      if (vm.Utils.roleCheck(['Teacher'])) {
        vm.Groups.myGroups()
          .then((data)=> {
            vm.groupsIndex = data;
          });
      } else {
        vm.Groups.groupsIndex()
          .then((data)=> {
            vm.groupsIndex = data;
          });
      }
    }
    report() {
      let vm = this;
      if (!vm.group_id) {
        return;
      }
      vm.reports = [];
      vm.Groups.pollMetrics({id: vm.group_id})
        .then((data)=> {
          angular.forEach(data.documents, function (d) {
            angular.forEach(d.poll_results, function (o) {
              let currentIndex = vm.reports.push([{key: 'Results', values: []}]) - 1;
              angular.forEach(o.poll.poll_results, function (pr) {
                if (pr.results.length > 0) {
                  vm.reports[currentIndex].html = pr.question.text;
                  angular.forEach(pr.results, function (r) {
                    vm.reports[currentIndex][0].values.push({label: r.option_label, value: r.count});
                  });
                } else {
                  vm.reports.splice(currentIndex, 1);
                }
              });
            });
          });
        });
    }
  }

  /**
   * @ngdoc object
   * @name components.controller:PollReportCtrl
   *
   * @description
   *
   */
  angular
    .module('components')
    .controller('PollReportCtrl', PollReportCtrl);
}());
